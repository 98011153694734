import { Button, Modal, Form } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { EmailDetails } from "../../Client/components/EmailDetails";
import { transactionLaneDataHandle } from "../state/actions";
import AddEmailModal from "../../PropertyComp/components/AddEmailModal";
import { PropertyHandlers } from "../../PropertyComp/state/actions";

const AddEmailModalRtd = ({ modalVisible, setModalVisible, selectedData, seller, filterSort, source, rtdData, delegateSide, componentUsedSection }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const successFunc = ({reducerFunc}) => {
    if(componentUsedSection === "TRANSACTION_DASHBOARD"){
      if(reducerFunc){
        dispatch(reducerFunc());
      }
    }
    else{
      dispatch(PropertyHandlers.changeFilterSort({...filterSort}));
    }
  }

  return (
    <>
      <Modal open={modalVisible.isAllEmailsModalOpen} width={"950px"} onCancel={() => setModalVisible({ ...modalVisible, isAllEmailsModalOpen: false })} footer={false} title={<p style={{ textAlign: "center", color: "#178DFA", fontSize: "22px", fontWeight: "bold" }}>Add Email</p>}>
        <>
          <div style={{ overflowX: "auto", paddingBottom: "20px", marginBottom: "10px" }}>
            {seller === "BUYER"
              ? (source === "RTD" ? rtdData?.buyerIds : selectedData?.buyerIds)?.map((buyer) => (
                  <div key={buyer._id} style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                    {!buyer.isCorp ? (
                      <div>
                        <span style={{ fontSize: "20px", cursor: "context-menu" }}>{buyer.fullName}</span>
                        <span style={{ fontSize: "25px", fontWeight: "bold", cursor: "context-menu", marginRight: "5px" }}> - </span> {buyer.personId?.email ? <span style={{ fontSize: "20px", color: "blue", cursor: "context-menu", textDecoration: "underline" }}>{buyer.personId.email}</span> : <AddEmailModal offerId={selectedData?.offerId} clientData={buyer} successFunc={successFunc} role={seller} delegate={delegateSide && true} />}
                      </div>
                    ) : (
                      <div>
                        <span style={{ fontSize: "20px", color: "orange" }}>
                          {buyer?.fullName || ""} {"Signers"}
                        </span>
                        {buyer?.signers?.map((ele) => (
                          <div style={{ marginLeft: "20px", display: "flex", alignItems: "center", whiteSpace: "nowrap", fontSize: "18px" }} key={ele?._id}>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                              <span style={{ fontSize: "20px", cursor: "context-menu" }}>{ele?.fullName}</span>
                              <span style={{ fontSize: "25px", fontWeight: "bold", cursor: "context-menu", marginLeft: "5px", marginRight: "5px" }}>-</span>
                              <span style={{ fontSize: "20px", color: "blue", textDecoration: "underline" }}>{ele.personId?.email ? <span style={{ fontSize: "20px", color: "blue", cursor: "context-menu", textDecoration: "underline" }}>{ele.personId.email}</span> : <AddEmailModal offerId={selectedData?.offerId} clientData={{ ...ele, isCorp: true }} successFunc={successFunc} role={seller} delegate={delegateSide && true} />}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              : (source === "RTD" ? rtdData?.sellerIds : selectedData?.sellerIds)?.map((seller) => (
                  <div key={seller._id} style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                    {!seller.isCorp ? (
                      <div>
                        <span style={{ fontSize: "20px", cursor: "context-menu" }}>{seller.fullName}</span>
                        <span style={{ fontSize: "25px", fontWeight: "bold", cursor: "context-menu", marginRight: "5px" }}> - </span> {seller.personId?.email ? <span style={{ fontSize: "20px", color: "blue", cursor: "context-menu", textDecoration: "underline" }}>{seller.personId.email}</span> : <AddEmailModal offerId={selectedData?.offerId} clientData={seller} successFunc={successFunc} role={seller} delegate={delegateSide && true} />}
                      </div>
                    ) : (
                      <div>
                        <span style={{ fontSize: "20px" ,color: "orange"}}>
                          {seller?.fullName || ""} {"Signers"}
                        </span>
                        {seller?.signers?.map((ele) => (
                          <div style={{ marginLeft: "20px", display: "flex", alignItems: "center", whiteSpace: "nowrap", fontSize: "18px" }} key={ele?._id}>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                              <span style={{ fontSize: "20px", cursor: "context-menu" }}>{ele?.fullName}</span>
                              <span style={{ fontSize: "25px", fontWeight: "bold", cursor: "context-menu", marginLeft: "5px", marginRight: "5px" }}>-</span>
                              <span style={{ fontSize: "20px", color: "blue", textDecoration: "underline" }}>{ele.personId?.email ? <span style={{ fontSize: "20px", color: "blue", cursor: "context-menu", textDecoration: "underline" }}>{ele.personId.email}</span> : <AddEmailModal offerId={selectedData?.offerId} clientData={{ ...ele, isCorp: true }} successFunc={successFunc} role={seller} delegate={delegateSide && true} />}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
          </div>
        </>
      </Modal>
    </>
  );
};

export { AddEmailModalRtd };
