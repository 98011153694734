import { Avatar, List, Modal, Spin } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EmptyData } from '../../Common/components/EmptyData';
import { accountHandle } from '../state/actions';
import moment from "moment";
import styles from "../styles/Logs.module.css";

export default function () {
    const dispatch = useDispatch();
    const { logsLoading, logsError, logsData} = useSelector(state => state.account);
    const [ openModal, setOpenModal ] = useState({
      value: false,
      timeStampSection: []
    })

    useEffect(()=>{
        dispatch(accountHandle.getLogDetails())
    }, [])

    let regex = /[""]/g;
    return (
        <div style={{padding: "10px", height: "calc(93vh - 4rem)", overflowY: "auto", display: "flex", flexDirection: "column", gap: "10px"}}>
            {
                logsLoading ? <div style={{display: "flex", justifyContent: "center", alignItems: "center",marginTop:"28%"}}><Spin size='large'/></div> : logsError ? "...error" : 
                logsData.length > 0 ? logsData?.map((item, idx) => {
                  return <div key={idx} style={{display: "flex", padding: "10px", gap: "10px", justifyContent: "space-between", ...(idx !== logsData?.length) ? {border: "1px solid rgb(206, 206, 206)"} : {}}}>
                    <div style={{display: "flex", gap: "10px", width: "100%"}}>
                      <div>
                        { item?.delegateId?.profileImg?.original ? <Avatar size={50} src={item.delegateId.profileImg.original} /> : <Avatar
                            size={50}
                            style={{
                              backgroundColor: "#085191"
                            }}
                          >
                            {( item?.delegateId?.firstName?.[0]?.toUpperCase() + item?.delegateId?.lastName?.[0]?.toUpperCase())}
                          </Avatar>}
                      </div>
                      <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "4px"}}>
                        <div className={styles.agentLogName}>{item?.delegateId?.fullName}</div>
                        <div className={styles.logDescription}>
                          {item?.description} <span style={{color: "blue", textDecoration: "underline", marginLeft: "5px"}}>{ (item?.multipleDescription?.length) ? <span style={{cursor: "pointer"}} onClick={()=>setOpenModal({...openModal, value: true, multipleDescription: item?.multipleDescription?.reverse()})}>View More</span> : ""}</span>
                        </div>
                        <div className={styles.idsLog}>
                          <div>
                            {
                              item?.offerId && `RE-Sure Offer ID: ${item?.offerId}`
                            }
                          </div>
                          <div>
                            {
                              item?.propertyId && `RE-Sure Listing ID: ${item?.propertyId}`
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "end", minWidth: "160px"}}>
                      <div className={styles.statusSection} style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <div style={{width: "10px",  height: "10px", borderRadius: "100px", ...(item?.type === "DELETED" ? {backgroundColor: "red"} : item?.type === "CREATED" ? {backgroundColor: "green"} : {backgroundColor: "rgb(8,81,145)"})}}></div>
                        {
                          item?.type
                        }
                      </div>
                      <div className={styles.timeStampSection}>
                        { 
                          moment(item?.updatedAt).format(
                            "MM-DD-YYYY hh:mm a",
                          )
                        }
                      </div>
                    </div>
                  </div>
                }) : <EmptyData/>
            }
            <Modal
              open={openModal?.value}
              width="80%"
              height="80%"
              onCancel={()=>setOpenModal({...openModal, value: false})}
              style={{ marginTop: "-50px" }}
              footer={false}
            >
              <div
                style={{
                  overflowY: "auto",
                  height: "80vh"
                }}
              >
                <table
                  style={{ borderCollapse: "collapse", border: "1px solid #bfbfbf" }}
                >
                  <thead
                    style={{
                      position: "sticky",
                      top: -2,
                      left: "0px",
                      zIndex: 100,
                      height: "50px",
                    }}
                  >
                    <tr>
                      <th
                        width="30%"
                        style={{
                          width: "30%",
                          background: "#2171B9",
                          color: "whitesmoke",
                        }}
                      >
                        FIELD
                      </th>
                      <th
                        style={{
                          width: "30%",
                          background: "#2171B9",
                          color: "whitesmoke",
                        }}
                      >
                        UPDATED FROM
                      </th>
                      <th
                        style={{
                          width: "30%",
                          background: "#2171B9",
                          color: "whitesmoke",
                        }}
                      >
                        UPDATED TO
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                      {openModal?.multipleDescription?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className={styles.idsLog}>
                              {item?.key}
                            </div>
                            <div className={styles.logDescription} style={{display: "flex", alignItems: "center", gap: "5px"}}>
                              <div style={{width: "10px",  height: "10px", borderRadius: "100px", backgroundColor: "rgb(143,143,143)"}}></div>
                              { 
                                moment(item?.createdAt).format(
                                  "MM-DD-YYYY hh:mm a",
                                )
                              }
                            </div>
                          </td>
                          <td>
                            <div>
                              {item?.previousDescription && JSON.stringify(item?.previousDescription)?.replace(regex, "")}
                            </div>
                          </td>
                          <td>
                            <div>
                              {item?.currentDescription && JSON.stringify(item?.currentDescription)?.replace(regex, "")}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div> 
            </Modal>
        </div>
  )
}