import { Avatar, Button, Modal } from 'antd'
import React from 'react'
import Transactions from '../../PropertyComp/components/Transactions';
import styles from "../styles/Delegates.module.css";

export default function DelegateProperty({ delegateSelected, showPropertyModal, setShowPropertyModal, handleDelegate, readOnly, selectedTransaction, setSelectedTransaction}) {
    return (
        <Modal
          className={styles.delegatePropertModal}
          style={{ marginTop: "-70px" }}
          title={
            <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 40px" }}>
                <span style={{ color: "grey", fontSize: "26px", margin: "10px 0px",}}>
                  Transactions
                </span>
                <div style={{ display: "flex", gap: "10px", alignItems: "center", margin: "5px 0px", lineHeight: "19px" }}>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "end"}}>
                    {delegateSelected?.fullName}
                    {
                      !readOnly?.value && <div style={{color: "grey"}}>{ selectedTransaction?.buying?.length + selectedTransaction?.sellingErts?.length + selectedTransaction?.sellingProperty?.length } selected</div>
                    }
                  </div>
                  {delegateSelected?.profileImg?.original ? (
                      <Avatar
                        style={{ border: "1px solid grey" }}
                        size={40}
                        src={delegateSelected?.profileImg?.original}
                      />
                  ) : (
                      <Avatar size={36} style={{ background: "#085191" }}> {delegateSelected?.fullName?.split(" ").shift()?.charAt(0)?.toUpperCase() +delegateSelected?.fullName?.split(" ").pop()?.charAt(0)?.toUpperCase()} </Avatar>
                  )}
                </div>
            </div>
          }
          visible={showPropertyModal}
          onCancel={() => {
            setShowPropertyModal(false);
          }}
          footer={null}
          width="80%"
        >
            <div style={{ marginTop: "-8px" }}>
              <Transactions section={"LIMITED_TRANSACTION"} setSelectedTransaction={setSelectedTransaction} selectedTransaction={selectedTransaction} readOnly={readOnly}/>
            </div>
            <div style={{ display: "flex", justifyContent: "center", padding: "8px 0px" }}>
              {
                !readOnly?.value && <Button
                  type="primary"
                  style={{
                    cursor: "pointer",
                    width: "10vw",
                  }}
                  onClick={handleDelegate}
                  disabled={(selectedTransaction?.buying?.length === 0) && (selectedTransaction?.sellingErts?.length === 0) && (selectedTransaction?.sellingProperty?.length === 0)}
                >
                  Assign
                </Button>
              }
            </div>
        </Modal>
    )
}
