import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// Get all Franchise created by a person
const getFranchiseRequest = (query) => {
  let accessTokan = localStorage.getItem("userAuth");

    return axios.get(`${apiURL}/office`, {
      params: {
        ...query,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });

};
// Profile Image upload
const uploadImageforProfileRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "PROFILE_PICTURES");

  return axios.post(`${apiURL}/common/uploadFile`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// person profile update
const updateUserProfileRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.put(`${apiURL}/person/profileUpdate`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// person update Password Request
const updatePasswordRequest = (data) => {
  delete data.confirm;
  let accessTokan = localStorage.getItem("userAuth");
  return axios.put(`${apiURL}/person/changePassword`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Get persons profile details
const getUserDetailsRequest = () => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/realtorDetailedData`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

/// delegateData

const getDelegateDetailsRequest = (query, delegate) => {
    let accessTokan = localStorage.getItem("userAuth");
    // Base URL
    let url = `${apiURL}/delegatesDetail?delegateValue=${query}`;
    // Conditionally add the extra query parameter
    if (delegate) {
      url += `&onlyFullAccess=true`;
    }
    return axios.get(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };


// Get clients profile details
const getClientDetailsRequest = () => {
  let accessTokan = localStorage.getItem("clientAuth");
  return axios.get(`${apiURL}/clientDetailedData`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Edit NAME details
const updateNameRequest = (data, event, updateLocation) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  if (updateLocation === "Designation") {
    params.append("designation", data.designation);
  } else {
    params.append("firstName", data.firstName);
    params.append("middleName", data.middleName);
    params.append("lastName", data.lastName);
  }
  return axios.put(`${apiURL}/person/profileUpdate`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
// Update Address
const updateAddressRequest = (values, data, personId) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("personId", personId);
  // params.append("officeName", values.officeName);
  params.append("streetNumber", values.streetNumber);
  params.append("streetName", values.streetName);
  params.append("unit", values.unit);
  params.append("city", values.city);
  params.append("state", values.state);
  params.append("zipCode", values.zipCode);
  params.append("country", values.country);
  if (data?.addressData && data?.addressData?._id) {
    return axios.put(`${apiURL}/office/${data?.brokerageData?._id}`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  } else {
    return axios.post(`${apiURL}/office`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }
};

// Creating a Licence Id
const createLicenceRequest = ({payload, delegateOwnerId}) => {
 let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("idType", payload?.idType);
  if (payload.id) {
    params.append("id", payload.id.trim());
   }
  if(delegateOwnerId) params.append("delegateOwnerId", delegateOwnerId);
  if (payload.licenseStatus) {
    params.append("licenseStatus", payload.licenseStatus);
  } 
  if(payload.notes) params.append("notes", payload.notes);
  if (payload.expiryDate) {
    params.append("expiryDate", payload.expiryDate);
  }

  return axios.post(`${apiURL}/personId`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
// Get all LicenceIds created by a person
const getLicenseRequest = ({source, delegateOwnerId}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/personIds`, {
    params: {
      ...(delegateOwnerId ? {delegateOwnerId} : {})
    },
    cancelToken: source.token,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// updateLicense Id
const updateLicenseRequest = ({licenseMongoId, payload}) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("idType", payload.idType);
  if (payload.id) {
   params.append("id", payload.id.trim());
  }
 
  if (payload.licenseStatus) {
    params.append("licenseStatus", payload.licenseStatus);
  } 
  if(payload.notes) params.append("notes", payload.notes);
  if (payload.expiryDate) {
    params.append("expiryDate", payload.expiryDate);
  }

  return axios.put(`${apiURL}/personId/${licenseMongoId}`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Deleting a licenseId
// may be required to do the changes in the payload
const deleteLicenseRequest = (licenseMongoId) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/personId/${licenseMongoId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const loginToDifferentAccount = (payload) => {
  let accessTokan = localStorage.getItem("userAuth");

  return axios.post(`${apiURL}/loginDiffAccount`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getLogDetailsRequest = () => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getLogs`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getSafeTDriveRequest = (page,limit,byKey,byAccDce) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/uploadedZip`, {
    params: {
      page: page,
      limit:limit,
      byKey,
      byAccDce
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
// create req
const createSafeTDriveRequest = (payload) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/extractZip`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// create req
const createOneCBSRSafeTDriveRequest = (payload) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/extractZipAndUploadfile`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// search req
const searchSafeTDriveRequest = (queryString,byKey,byAccDce) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/uploadedZip/search?${queryString}`, {
    params: {
      byKey:byKey,
      byAccDce:byAccDce,
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// search req
const getSafeTDrivebufferDataRequest = (payload) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/getAwsBufferData`,{...payload}, {
    headers: {
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const deleteDriveFolderRequest = (id) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/deleteZipFolder/${id}`,{
    headers:{
      authorization: `bearer ${accessTokan}`,
      accept:"application/json",
    }
  })
}

const getDelegateListRequest = (Id,source) => {
    let accessTokan = localStorage.getItem("userAuth");
    if(source){
        return axios.get(`${apiURL}/getDelegateList?email=${Id}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              accept: "application/json",
              authorization: `bearer ${accessTokan}`,
            },
          });
    }else{
    return axios.get(`${apiURL}/getDelegateList?personId=${Id}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
   }
  };

  const updatePerson = (payload) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.put(
      `${apiURL}/person/profileUpdate`,
      {
        ...payload,
      },
      {
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  };

  const getPartialDelegateProperties = (id, delegateTo) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.get(`${apiURL}/getPartialTransactionData?delegateAssignedId=${id}&delegateTo=${delegateTo}`,
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }

//   email signature methods
const uploademailSignRequest = (payload) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.post(`${apiURL}/createEmailSignature`,{...payload}, {
      headers: {
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

  const uploadImageforEmailSignRequest = (data) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.post(`${apiURL}/common/uploadFile`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

  const getEmailSignRequest = ({source}) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.get(`${apiURL}/getEmailSignature`,
      {
        cancelToken: source.token,
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }

  const deleteEmailSignRequest = (id) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.delete(`${apiURL}/deleteEmailSignature/${id}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

  const updateEmailSignRequest = (payload, id) => {
    let accessToken = localStorage.getItem("userAuth");

    const params = new URLSearchParams();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params.append(key, payload[key]);
      }
    }

    return axios.put(`${apiURL}/updateEmailSignature/${id}`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
    });
  };

  const editBrokerage = (urlData,logoImage) => {
    let accessToken = localStorage.getItem("userAuth");

    if(logoImage?.length){
      urlData = {...urlData,brokerageLogo:logoImage?.[0]?.file}
    }

    return axios.put(`${apiURL}/brokerageInfo`,urlData,{
      headers:{
        "Content-Type":"application/json",
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      }
    })
  }


export const accountDataApi = {
  getFranchiseRequest,
  uploadImageforProfileRequest,
  updateUserProfileRequest,
  getUserDetailsRequest,
  getClientDetailsRequest,
  updatePasswordRequest,
  updateNameRequest,
  updateAddressRequest,
  createLicenceRequest,
  getLicenseRequest,
  updateLicenseRequest,
  deleteLicenseRequest,
  loginToDifferentAccount,
  getLogDetailsRequest,
  getSafeTDriveRequest,
  createSafeTDriveRequest,
  createOneCBSRSafeTDriveRequest,
  searchSafeTDriveRequest,
  getSafeTDrivebufferDataRequest,
  getDelegateListRequest,
  updatePerson,
  getPartialDelegateProperties,
  getDelegateDetailsRequest,
  uploademailSignRequest,
  uploadImageforEmailSignRequest,
  getEmailSignRequest,
  deleteEmailSignRequest,
  updateEmailSignRequest,
  editBrokerage,
  deleteDriveFolderRequest,
};
