import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const transactionLaneApi = ({offerId, role, source, delegateSide}) => {
  let accessTokan = localStorage.getItem("userAuth");
  const extraQuery = delegateSide ? `&delegateSide=${delegateSide}` : "";
  return axios.get(`${apiURL}/RTD/${offerId}?role=${role}` + extraQuery , {
    cancelToken: source.token,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getTitleCompanyData=({offerId, source, delegateSide})=>{
  const accessTokan = localStorage.getItem("userAuth");
  const extraQuery = delegateSide ? `&delegateSide=${delegateSide}` : "";
  return axios.get(`${apiURL}/getSelectedTitleCompanyData?offerId=${offerId}` + extraQuery,{
    cancelToken: source.token,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
  })
}

const individualTransactionApi = ({offerId, ertsId, source, delegateSide}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getIndividualTransaction`, {
    params: {
      ...(offerId ? {offerId} : {}),
      ...(ertsId ? {ertsId} : {}),
      ...(delegateSide ? {delegateSide} : {})
    },
    cancelToken: source.token,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getPropertyDocsRequest = ({offerId, source, delegateSide}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getSharedDocs`,
  {
    cancelToken: source.token,
    params: {
      offerId: offerId,
      ...(delegateSide ? {delegateSide} : {})
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const draftDocumetnApi = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/url/builtForSection`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const updateDocumentApi = ({documentId, data, contractType, delegateSide, emailSignatureId, emailMessage}) => {
  let accessTokan = localStorage.getItem("userAuth");
  let apiUrl = `${apiURL}/offerDocument/${documentId}`;
  return axios.put(
    apiUrl,
    {
      ...data,
      ...(delegateSide ? {delegateSide} : {}),
      ...(emailSignatureId ? {emailSignatureId} : {}),
      ...(emailMessage ? {emailMessage} : {}),
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
}

const sendEmailToClientRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/offerDocument/sendDocument`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getClientScreen = (params) => {

  let accessToken = localStorage.getItem("userAuth");
  return axios.get(
    `${apiURL}/clientRTD`,
    {
      params,

    headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};


const getEmails_FilesApi = (params) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(
    `${apiURL}/emailsAndFiles`,
    {
    params,
    headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};
const getClientDocRequest = (params) => {

  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/propertyDocs`,{
      params,
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const deleteFormRequest = ({documentId, offerId, delegateSide, isToggleFrom}) => {

  let accessTokan = localStorage.getItem("userAuth");
  let params = {
    ...(delegateSide ? {delegateSide} : {}),
    ...(offerId ? {offerId} : {}),
    ...(isToggleFrom ? {isToggleFrom} : {})
  };
  return axios.delete(`${apiURL}/RTD/deleteForm/${documentId}`, {
    params,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
}

const urlShorthandApi = (data,source) => {
  let accessTokan = localStorage.getItem("userAuth");
  if(source === 'calendar'){
    return axios.post(`${apiURL}/url`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }else{
    return axios.post(`${apiURL}/url/builtForSection`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }
};

const addEmail = (data) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/addClientDetail`,{...data},{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
  })
}

const getNestedDoraDocuments = ({data, fiters, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  let params = {
    listType: data.listType,
    offerId: data.offerId,
    ...(fiters?.search ? {search: fiters?.search} : {})
  }
  let url = `${apiURL}/nestedContractDocumentaries`;
  return axios.get( url,
    {
      params,
      cancelToken: source.token,
      headers: {
        accept: "application/json",
        // authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const addTitleoffice = ({payload, delegateSide}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/updateTitleOrderForm`,
  {
    ...payload,
    ...(delegateSide ? {delegateSide} : {})
  },
  {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const gettitlecompany=({type, id, search, source})=>{
  const accessTokan = localStorage.getItem("userAuth");
  let query = "";
  if(type === "COMPANY"){
    query = `?titleType=TITLE_COMPANY&includeLimitedData=true`;
  }
  else if(type==="OFFICE"){
    query = `?titleType=TITLE_OFFICE&parentId=${id}`;
    if(search){
      query += `&search=${search}`;
    }
  }
  return axios.get(`${apiURL}/titleOffice${query}`,{
    cancelToken: source.token,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  },
  })
}

const sendTitleCompanyDocument = (payload) => {

  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/sendMultipleDocuments`,{...payload},{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
  })
}

const deleteTitleCompanyData=({offerId, delegateSide})=>{
  const accessTokan = localStorage.getItem("userAuth");
  let extraQuery = delegateSide ? `&delegateSide=${delegateSide}` : "";
  return axios.delete(`${apiURL}/clearTitleOrderFormData?offerId=${offerId}` + extraQuery,{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  },
  })
}

export const transactionLaneDataApi = {
  sendEmailToClientRequest,
  transactionLaneApi,
  draftDocumetnApi,
  updateDocumentApi,
  getClientScreen,
  getClientDocRequest,
  getEmails_FilesApi,
  deleteFormRequest,
  individualTransactionApi,
  getPropertyDocsRequest,
  getTitleCompanyData,
  urlShorthandApi,
  addEmail,
  getNestedDoraDocuments,
  addTitleoffice,
  gettitlecompany,
  sendTitleCompanyDocument,
  deleteTitleCompanyData
};
