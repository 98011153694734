import { transactionDefaultSortFilter } from "../../Common/commondata/commonData";
import { PropertyConstants } from "./action-types";

const initialState = {
  propertyData: [],
  tasksData: [],
  currentlySelectedData: [],
  propertyDataLoading: false,
  propertyDataError: false,

  propertyDocs: [],
  propertDocsError: false,
  propertyDataErrorMsg: null,
  propertDocsLoading: false,

  doraDocsUrlShorthandData: null,
  doraDocsUrlShorthandLoading: false,
  doraDocsUrlShorthandFailure: null,

  getPreDoraDocumentsData: [],
  getPreDoraDocumentsLoading: false,
  getPreDoraDocumentsError: null,

  needToKnowLoading: false,
  needToKnowData: null,
  needToKnowError: false,
  needToKnowErrorMsg: null,

  getPropertyLicenseLoading: false,
  getPropertyLicenseData: null,
  getPrpertyLicenseError: false,
  getPrpertyLicenseErrorMsg: null,

  updateNeedToKnow: [],

  linkingMlsLoading:false,
  linkingMlsData:[],
  linkingMlsError:null,
  linkingMlsErrorMsg: null,

  deLinkingMlsLoading:false,
  deLinkingMlsData:[],
  deLinkingMlsError:false,

  urlShorthandData: [],
  urlShorthandLoading: false,
  urlShorthandError: null,

  PropertyImageData:[],
  PropertyImageDataLoading:false,
  propetyImageDataError:null,

  updatedPropertyDoc: [],

  updatedPropertTask:[],

  filterSort: {...transactionDefaultSortFilter},
  uploadedPdfDocSuccess: [],
};

const PropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case PropertyConstants.GET_PROPERTY_DATA_LOADING:
      return {
        ...state,
        propertyDataLoading: true,
        propertyDataError: false,
        propertyData: action?.pageNumber === 1 ? [] : [...state.propertyData],
      };
    case PropertyConstants.GET_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        propertyData: [...state.propertyData, ...action?.tcData],
        propertyDataLoading: false,
        propertyDataError: false,
      };
    case PropertyConstants.GET_PROPERTY_DATA_ERROR:
      return {
        ...state,
        propertyDataError: true,
        propertyDataErrorMsg: action.getTcDataError,
        propertyDataLoading: false,
      };
    case PropertyConstants.GET_SEARCH_PROPERTY_DATA_LOADING:
      return {
        ...state,
        propertyData: [],
        propertyDataLoading: true,
        propertyDataError: null,
      };
    case PropertyConstants.GET_SEARCH_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        propertyData: action?.searchData,
        propertyDataLoading: false,
        propertyDataError: null,
      };
    case PropertyConstants.GET_SEARCH_PROPERTY_DATA_ERROR:
      return {
        ...state,
        propertyDataError: true,
      };
    case PropertyConstants.SET_SELECTED_PROPERTY_DATA:
      return {
        ...state,
        currentlySelectedData: action.selectedData,
      };
    // property docs methods
    case PropertyConstants.GET_CLIENT_DOC_LOADING:
      return {
        ...state,
        propertDocsLoading: true,
        propertDocsError: null,
      };
    case PropertyConstants.GET_CLIENT_DOC_SUCCESS:
      return {
        ...state,
        propertyDocs: action.propertyDocs,
        propertDocsLoading: false,
        propertDocsError: null,
      };
    case PropertyConstants.GET_CLIENT_DOC_ERROR:
      return {
        ...state,
        propertyDocs: [],
        propertDocsLoading: false,
        propertDocsError: null,
      };

    case PropertyConstants.RESET_PROPERTY_DOCS:
        return {
          ...state,
          propertyDocs: [],
          propertDocsLoading: false,
          propertDocsError: null,
        };

    // Dora documents methods
    case PropertyConstants.DORA_DOCS_SHORTHAND_URL_LOADING:
      return {
        ...state,
        doraDocsUrlShorthandLoading: true,
        doraDocsUrlShorthandFailure: false,
      };

    case PropertyConstants.DORA_DOCS_SHORTHAND_URL_SUCCESS:
      return {
        ...state,
        doraDocsUrlShorthandLoading: false,
        doraDocsUrlShorthandFailure: false,
      };
    case PropertyConstants.DORA_DOCS_SHORTHAND_URL_FAILURE:
      return {
        ...state,
        doraDocsUrlShorthandLoading: false,
        doraDocsUrlShorthandFailure: true,
      };

    // Tasks Data
    case PropertyConstants.GET_TASKS_DATA_LOADING:
      return {
        ...state,
        getTasksDataLoading: true,
        // tasksData: null,
        getTasksDataError: false,
      };
    case PropertyConstants.GET_TASKS_DATA_SUCCESS:
      return {
        ...state,
        tasksData: action.getTasksData,
        getTasksDataLoading: false,
        getTasksDataError: false,
      };
    case PropertyConstants.GET_TASKS_DATA_ERROR:
      return {
        ...state,
        tasksData: [],
        getTasksDataLoading: false,
        getTasksDataError: true,
      };
    case PropertyConstants.SET_TASKS_LOADING:
      return {
        ...state,
        getTasksDataLoading: action.getTasksDataLoading,

      };

    case PropertyConstants.GET_PRE_DORA_DOCUMENTS_LOADING:
      return {
        ...state,
        getPreDoraDocumentsLoading: true,
        getPreDoraDocumentsError: null,
      };
    case PropertyConstants.GET_PRE_DORA_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getPreDoraDocumentsData: action.getPreDoraDocumentsData,
        getPreDoraDocumentsLoading: false,
        getPreDoraDocumentsError: null,
      };
    case PropertyConstants.GET_PRE_DORA_DOCUMENTS_FAILURE:
      return {
        ...state,
        getPreDoraDocumentsLoading: false,
        getPreDoraDocumentsError: true,
      };

    case PropertyConstants.NEED_TO_KNOW_LOADING:
      return {
        ...state,
        needToKnowLoading: action.value,
        needToKnowError: false,
      };
    case PropertyConstants.NEED_TO_KNOW_SUCCESS:
      return {
        ...state,
        needToKnowData: action.NeedToKnowData,
        needToKnowLoading: false,
        needToKnowError: false,
      };
    case PropertyConstants.NEED_TO_KNOW_FAILURE:
      return {
        ...state,
        needToKnowLoading: false,
        needToKnowError: true,
        needToKnowErrorMsg: action.errorMsg,
      };
    case PropertyConstants.UPDATE_NEED_TO_KNOW:
      return {
        ...state,
        updateNeedToKnow: action.updateNeedToKnowData,
        needToKnowLoading: false,
        needToKnowError: false,
      };
    case PropertyConstants.GET_CONSTANTS_LOADING:
      return {
        ...state,
        getPropertyLicenseLoading: true,
        getPrpertyLicenseError: false,
      };
    case PropertyConstants.GET_CONSTANTS_SUCCESS:
      return {
        ...state,
        getPropertyLicenseData: action.getPropertyLicenseData,
        getPropertyLicenseLoading: false,
        getPrpertyLicenseError: false,
      };
    case PropertyConstants.GET_CONSTANTS_FAILURE:
      return {
        ...state,
        getPropertyLicenseLoading: false,
        getPrpertyLicenseError: action.getPrpertyLicenseError,
        getPrpertyLicenseErrorMsg: action.errorMsg,
      };

    case PropertyConstants.LINK_MLS_LOADING:
      return {
        ...state,
        linkingMlsLoading:true,
      };
    case PropertyConstants.LINK_MLS_SUCCESS:
      return {
        ...state,
        linkingMlsLoading:false,
        linkingMlsData:action.linkmlsData,
      };
    case PropertyConstants.LINK_MLS_ERROR:
        return {
          ...state,
          linkingMlsLoading:false,
          linkingMlsError:action.error,
          linkingMlsErrorMsg: action.errorMsg,
        };
      case PropertyConstants.DELINK_MLS_LOADING:
        return {
          ...state,
          deLinkingMlsLoading:true,
          deLinkingMlsError: false
        };
      case PropertyConstants.DELINK_MLS_SUCCESS:
        return {
          ...state,
          deLinkingMlsLoading:false,
          deLinkingMlsError: false,
          deLinkingMlsData: [],
        };
      case PropertyConstants.DELINK_MLS_ERROR:
          return {
            ...state,
            deLinkingMlsLoading:false,
            deLinkingMlsError: true
          };

    case PropertyConstants.SHORTHAND_URL_LOADING:
      return {
        ...state,
        urlShorthandLoading: true,
        urlShorthandError: null,
      };
    case PropertyConstants.SHORTHAND_URL_SUCCESS:
      return {
        ...state,
        urlShorthandData: action,
        urlShorthandLoading: false,
        urlShorthandError: null,
      };
    case PropertyConstants.SHORTHAND_URL_FAILURE:
      return {
        ...state,
        urlShorthandData: [],
        urlShorthandLoading: false,
        urlShorthandError: null,
      };
  // Property Images methods
  case PropertyConstants.POST_PROPERTY_IMAGE_LOADING:
    return {
      ...state,
      PropertyImageDataLoading: true,
      propetyImageDataError: null,
    };
  case PropertyConstants.POST_PROPERTY_IMAGE_SUCESS:
    return {
      ...state,
      PropertyImageData: [],
    };
    case PropertyConstants.POST_PROPERTY_IMAGE_ERROR:
    return {
      ...state,
      PropertyImageData: [],
      PropertyImageDataLoading: false,
      propetyImageDataError: action.propetyImageDataError,
    };
    case PropertyConstants.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        updatedPropertyDoc: []
      }
    case PropertyConstants.TASK_UPADTED:
        return {
          ...state,
          updatedPropertTask:[],
        };

    //Change filter sort
    case PropertyConstants.CHANGE_FILTER_SORT:
      return {
        ...state,
        filterSort: action.filterSort,
        propertyData: []
      };
    case PropertyConstants.UPLOAD_DOCUMENT_PDF_SUCCESS:
      return {
        ...state,
        uploadedPdfDocSuccess: []
      }

    default:
      return state;
  }
};
export { PropertyReducer };
