

  export const filteredTransactionData = ({ele, selectedData, source}) => {
    let transactionData = selectedData?.newTransactionData || selectedData?.transactionData || {}
    // if (ele.prefillValues) {
    //   let allInputsToDelete = Object.keys(ele.prefillValues);
    //   console.log("allInputsToDelete", allInputsToDelete);
    //   allInputsToDelete?.forEach((element) => {
    //     delete transactionData[element];
    //   });
    // }
    if (source === "PROPERTY_DOCS") {
      delete transactionData["dateTable"];
      delete transactionData["addClauseData"];
      delete transactionData["addDocumentData"];
    }
    return selectedData;
  }

export const removePersonObject = (corporateClients=[]) => {
   for(let i = 0; i<corporateClients?.length ; i++){
      corporateClients[i] = {
        ...corporateClients[i]
      }
      if(corporateClients?.[i]?.isCorp){
        corporateClients[i].signers = [
          ...corporateClients[i].signers
        ]
        for(let j = 0; j<corporateClients?.[i]?.signers?.length ; j++){
          corporateClients[i].signers[j] = {
            ...corporateClients[i].signers[j]
          }
          corporateClients[i].signers[j].personId = corporateClients?.[i]?.signers[j]?.personId?._id || corporateClients?.[i]?.signers[j]?.personId;
        }
      }
      else{
        corporateClients[i].personId = corporateClients?.[i]?.personId?._id || corporateClients?.[i]?.personId;
      }
    }
    return corporateClients || [];
  }


 const mergeSigners = (existingSigners = [], newSigners = []) => {
   const seenSignerIds = new Set(existingSigners.map((signer) => signer.personId?._id || signer.key));
   return [
     ...existingSigners,
     ...newSigners.filter((newSigner) => {
       const signerId = newSigner.personId?._id || newSigner.key;
       return !seenSignerIds.has(signerId);
     }),
   ];
 };

 const processClients = (clientIds = [], corpMap, individualMap) => {
   clientIds.forEach((client = {}) => {
     const { isCorp, key, personId, signers = [] } = client;
     if (isCorp) {
       if (!corpMap.has(key)) {
         corpMap.set(key, { ...client, signers: signers || [] });
       } else {
         const existingCorpClient = corpMap.get(key);
         const mergedSigners = mergeSigners(existingCorpClient.signers, signers);
         corpMap.set(key, { ...existingCorpClient, signers: mergedSigners });
       }
     } else {
       const personIdValue = personId?._id || key;
       if (!individualMap.has(personIdValue)) {
         individualMap.set(personIdValue, { ...client, signers: signers || [] });
       }
     }
   });
 };
 export const getcombinedClients = ({ allContractDocuments = [], condition }) => {
   const corpSellers = new Map();
   const individualSellers = new Map();
   const corpBuyers = new Map();
   const individualBuyers = new Map();
   allContractDocuments?.forEach((document = {}) => {
     if (condition === "SELLER" || !condition) {
       processClients(document?.sellerIds, corpSellers, individualSellers);
     }
     if (condition === "BUYER" || !condition) {
       processClients(document?.buyerIds, corpBuyers, individualBuyers);
     }
   });
   return {
     combinedSellerIds: condition === "BUYER" ? [] : [...corpSellers.values(), ...individualSellers.values()],
     combinedBuyerIds: condition === "SELLER" ? [] : [...corpBuyers.values(), ...individualBuyers.values()],
   };
 };
 // function to combine unique agents into a map
 const addUniqueAgents = (agents, agentMap, openFor) => {
   if (!agents) return;
   agents.forEach((agent) => {
     if (agent && agent._id && !agentMap.has(agent._id)) {
       agentMap.set(agent._id, { _id: agent._id, personId: { _id: agent._id, email: agent.email, fullName: agent.fullName }, openFor, isAgent: true, fullName: agent.fullName });
     }
   });
 };

 export const getcombinedAgents = ({ allContractDocuments, condition }) => {
   if (!allContractDocuments?.length) {
     return { combinedBuyerAgentIds: [], combinedSellerAgentIds: [] };
   }
   if (condition === "BUYER_AGENT") {
     const uniqueBuyerAgentMap = new Map();
     allContractDocuments.forEach((document) => {
       if (document.buyerAgentId) {
         addUniqueAgents([document.buyerAgentId], uniqueBuyerAgentMap, "BUYER_AGENT");
       }
       if (document.coBuyerAgentIds) {
         addUniqueAgents(document.coBuyerAgentIds, uniqueBuyerAgentMap, "BUYER_AGENT");
       }
     });
     return {
       combinedBuyerAgentIds: Array.from(uniqueBuyerAgentMap.values()),
       combinedSellerAgentIds: [],
     };
   } else if (condition === "SELLER_AGENT") {
     const uniqueSellerAgentMap = new Map();
     allContractDocuments.forEach((document) => {
       if (document.sellerAgentId) {
         addUniqueAgents([document.sellerAgentId], uniqueSellerAgentMap, "SELLER_AGENT");
       }
       if (document.coSellerAgentIds) {
         addUniqueAgents(document.coSellerAgentIds, uniqueSellerAgentMap, "SELLER_AGENT");
       }
     });
     return {
       combinedBuyerAgentIds: [],
       combinedSellerAgentIds: Array.from(uniqueSellerAgentMap.values()),
     };
   } else {
     const uniqueBuyerAgentMap = new Map();
     const uniqueSellerAgentMap = new Map();
     allContractDocuments.forEach((document) => {
       if (document.buyerAgentId) {
         addUniqueAgents([document.buyerAgentId], uniqueBuyerAgentMap, "BUYER_AGENT");
       }
       if (document.coBuyerAgentIds) {
         addUniqueAgents(document.coBuyerAgentIds, uniqueBuyerAgentMap, "BUYER_AGENT");
       }
       if (document.sellerAgentId) {
         addUniqueAgents([document.sellerAgentId], uniqueSellerAgentMap, "SELLER_AGENT");
       }
       if (document.coSellerAgentIds) {
         addUniqueAgents(document.coSellerAgentIds, uniqueSellerAgentMap, "SELLER_AGENT");
       }
     });

     return {
       combinedBuyerAgentIds: Array.from(uniqueBuyerAgentMap.values()),
       combinedSellerAgentIds: Array.from(uniqueSellerAgentMap.values()),
     };
   }
 };

 export const mapClientDocs = (docsTabDoraDocs) => {
   let clientDocuments = [];
   docsTabDoraDocs?.forEach((doc) => {
     if (doc.createdDocuments?.length > 0) {
       doc.createdDocuments.forEach((subDoc) => {
         clientDocuments.push(subDoc);
         if (subDoc.matchedDocuments?.length > 0) {
           subDoc.matchedDocuments.forEach((matchDocument) => {
             clientDocuments.push(matchDocument);
           });
         }
       });
     }
   });
   return clientDocuments;
 };
  
  
  
  
