import React, { useEffect } from "react";
import { Alert, Avatar, Button, List, Modal } from "antd";
import { EmailDetails } from "../../Client/components/EmailDetails";
import { useState } from "react";
import { PropertyHandlers } from "../state/actions";
import { useDispatch, useSelector } from "react-redux";
import { generateInitials } from "../../Common/utils/extraFunctions";
import { Loading } from "../../Common/components/Loading";
const AddEmailModal = ({ clientData, offerId, successFunc, delegate, role }) => {
  const dispatch = useDispatch();
  const [enterEmailModal, setEnterEmailModal] = useState({
    open: false,
    data: {},
    responseData: {},
  });
  const [loading, setLoading] = useState(false);

  const handleAddEmail = (values, createClient) => {
    let data = {
      addToProperty: false,
      offerId,
      ...(delegate ? (role === "SELLER" ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {}),
      ...(createClient ? { newClient: true } : {}),
      ...(role === "BUYER"
        ? {
            buyerDetail: {
              fullName: clientData.personId?.fullName ? clientData.personId.fullName : clientData.fullName,
              key: clientData.key,
              isCorp: clientData.isCorp,
              ...(values?.email?.length > 0 ? { email: values.email.toLowerCase() } : {}),
            },
          }
        : {
            sellerDetail: {
              fullName: clientData.personId?.fullName ? clientData.personId.fullName : clientData.fullName,
              key: clientData.key,
              isCorp: clientData.isCorp,
              ...(values?.email?.length > 0 ? { email: values.email.toLowerCase() } : {}),
            },
          }),
    };
    setEnterEmailModal({
      ...enterEmailModal,
      data,
    });
    handleSave({ data });
  };

  const handleSave = ({ data }) => {
    if (enterEmailModal?.responseData?.showClients) {
      Modal.confirm({
        title: `Are you sure you want to ${data?.newClient ? "create a new contact ?" : "select this contact ?"}`,
        okText: "Yes",
        cancelText: "No",
        onOk() {
          dispatch(PropertyHandlers.addEmail({ data, successFunc, setCurrentData: setEnterEmailModal, setLoading }));
        },
        onCancel() {
          console.log("Cancelled");
        },
      });
    } else {
      dispatch(PropertyHandlers.addEmail({ data, successFunc, setCurrentData: setEnterEmailModal, setLoading }));
    }
  };
  return (
    <>
      {/* Modal for adding the email  */}
      {enterEmailModal?.open && (
        <Modal
          open={enterEmailModal?.open}
          footer={false}
          title={
            <div>
              <p style={{ textAlign: "center", color: "#178DFA", fontSize: "22px", fontWeight: "bold" }}>{enterEmailModal?.responseData?.showClients ? "Duplicate Contacts Found" : "Add Email"}</p>
              {enterEmailModal?.responseData?.showClients && (
                <Alert
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                    margin: "auto",
                  }}
                  message="Please select if current client exists from the list below or create a new contact."
                  type="info"
                  showIcon
                />
              )}
            </div>
          }
          onCancel={() => setEnterEmailModal({ open: false })}
        >
          {loading ? (
            <Loading />
          ) : enterEmailModal?.responseData?.showClients ? (
            <>
              <div></div>
              <div style={{ height: "300px", overflow: "hidden", overflowY: "auto" }}>
                <List
                  itemLayout="horizontal"
                  dataSource={enterEmailModal?.responseData?.allClients}
                  renderItem={(item) => (
                    <List.Item
                      onClick={() => handleSave({ data: { ...enterEmailModal?.data, selectedId: item?._id } })}
                      style={{ gap: 10, padding: 5, borderRadius: "5px" }}
                      hoverable
                      onMouseEnter={(e) => {
                        e.currentTarget.style.background = "#a8c5dc";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.background = "white";
                      }}
                    >
                      <List.Item.Meta avatar={item.profileImg?.original ? <Avatar style={{ background: "#085191" }} src={item.profileImg.original} /> : <Avatar style={{ background: "#085191" }}>{generateInitials(item.fullName)}</Avatar>} title={item.fullName} />
                    </List.Item>
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  margin: "10px auto auto auto",
                }}
              >
                <span style={{ border: "2px solid grey", flex: 1 }}></span>
                <span style={{ color: "grey", margin: "0 10px" }}>OR</span>
                <span style={{ border: "2px solid grey", flex: 1 }}></span>
              </div>
              <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <Button onClick={() => handleSave({ data: { ...enterEmailModal?.data, newClient: true } })} style={{ textAlign: "center" }} type="primary">
                  Create New
                </Button>
              </div>
            </>
          ) : (
            <EmailDetails onFinish={handleAddEmail} />
          )}
        </Modal>
      )}
      <Button
        size="small"
        type="primary"
        onClick={() => {
          setEnterEmailModal({
            ...enterEmailModal,
            open: true,
          });
        }}
      >
         Add Email
      </Button>
    </>
  );
};

export default AddEmailModal;
