import { Checkbox, message } from "antd";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
import axios from "axios";
import { clientAction } from "../../Client/state/actions";
import moment from "moment";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import { FilePdfOutlined } from "@ant-design/icons";
const DocumentList = ({documentId, clickedItem,data, setData, selectedData, section, selectedStatus, nestedModalOpen, RTDInitiator, reloadSection,role,selectedClient,screenSource }) => {
  const delegate = section === "DELEGATE";
  const crmBuyerDocs = ['ERTB', 'BDB', 'LCAE'];
  const crmSellerDocs = [
    'BDS',
    'DWR',
    'ERTL',
    'ETCT',
    'LCAES_ERTL',
    'LCAES_ETCT',
  ];
  const { getTransactionLaneData, getTransactionLaneError, getTransactionLaneLoading, getPropertyDocsData, getPropertyDocsLoading, getPropertyDocsError, getPropertyDocsErrorMsg, getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  let { propertyDocs, propertDocsLoading, propertDocsError } = useSelector((state) => state.PropertyReducer, shallowEqual);
  const { personDocs, personDetails, personDocsLoading } = useSelector((state) => state.client.personDetailReducer, shallowEqual);
  const dispatch = useDispatch();

  let unmounted = {
    value: false,
  };
  let source = axios.CancelToken.source();
  const getClientDocName = (data,name) => {
    
    let {contractType, transactionData = {}} = data;
    switch (contractType) {
      case 'ERTL':
      case 'ETCT':
      case 'ERTB': {
        let startDate = transactionData?.ListingPeriodBeginDate
          ? moment(transactionData?.ListingPeriodBeginDate).format("MM/DD/YYYY, h:mm A")
          : '';
        let endDate = transactionData?.ListingPeriodEndByDate
          ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A")
          : '';
        let docName = contractType;
  
        return (
          startDate && endDate ?`${name} ${startDate} - ${endDate} `:
          startDate?`${name} ${startDate}`:
          endDate?`${name} ${endDate}`:
          `${name}`
        )
      }
      case 'LCAE':
      case 'LCAES': 
      case 'LCAES_ETCT':{
        let endDate = transactionData?.ListingPeriodEndByDate
          ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A")
          : '';
        return (endDate ?`Amend/Extend -  ${endDate}`:`Amend/Extend`);
      }
  
      default:
        let dateTime = data?.createdAt
          ? moment(data?.createdAt).format("MM/DD/YYYY, h:mm A")
          : '';
        return `${name} ${dateTime}`;
    }
  };
  const mapClientDocs = (docsTabDoraDocs, propertyDocsData) => {
    let clientDocuments = [];
  
    docsTabDoraDocs?.forEach((doc) => {
      if (doc.createdDocuments?.length > 0) {
        
        doc.createdDocuments.forEach((subDoc) => {
          subDoc.contractName = getClientDocName(subDoc,doc?.name);
            clientDocuments.push(subDoc);
          if (subDoc.matchedDocuments?.length > 0) {
            subDoc.matchedDocuments.forEach((matchDocument) => {
              matchDocument.contractName = getClientDocName(matchDocument);
              matchDocument.isSecondLevel = true;
              clientDocuments.push(matchDocument);
            });
          } 
          
            
          
        });
      }
    });
  
  
    return clientDocuments;
  };
  const clientDocs = mapClientDocs(personDocs);
  
  useEffect(() => {
    
    // Filter contracts based on documentId
    const filteredContracts = getTransactionLaneData?.filter(contract => {
      return contract.documentId === documentId;
    });
    const filteredClientContracts =clientDocs?.filter(contract => {
      return contract._id === documentId;
    });
    
    const propertyDocuments = propertyDocs?.filter(contract => {
      return contract.documentId ===documentId;
    });
    // if (filteredContracts && filteredContracts.length > 0) {
    //   // Extract documentIds from filtered contracts
    //   const documentIds = filteredContracts.map(contract => contract.documentId)
    //   .filter(documentId => documentId !== undefined);;
    //   // Update state with the filtered documentIds
    //   setData(prevState => ({
    //     ...prevState,
    //     documentSelected: documentIds,
    //   }));
    // }
if (filteredClientContracts && filteredClientContracts.length > 0) {
    // Extract both documentIds and sentBy from filtered contracts
    const documentDetails = filteredClientContracts
        .map(contract => ({
            documentId: contract?._id,
            sentBy: crmSellerDocs.includes(contract?.contractType)?"SELLER_AGENT":"BUYER_AGENT" // Ensure that the sentBy field exists in your contracts
        }))
        .filter(({ documentId, sentBy }) => documentId !== undefined && sentBy !== undefined); // Filter out any undefined values
    // Update state with the filtered documentDetails
    setData(prevState => ({
        ...prevState,
        clientDocumentSelected: documentDetails,
    }));
}
 
    if (propertyDocuments && propertyDocuments.length > 0) {
      // Extract documentIds from filtered contracts
      
      const documentIds = propertyDocuments.map(contract => contract?.documentId)
      .filter(documentId => documentId !== undefined);
      ;
      // Update state with the filtered documentIds
      setData(prevState => ({
        ...prevState,
        propertyDocsSelected: documentIds,
      }));
     
    }
    
  }, [getTransactionLaneData,propertyDocs,personDocs])
  

  useEffect(() => {
    const delegateSide = delegate && selectedStatus;
    const client = RTDInitiator === "SELLER" || selectedData?.isErts || selectedData?.isProperty ? "SELLER" : "BUYER";
    if (nestedModalOpen?.isclearTransactionLaneData) {
      dispatch(transactionLaneDataHandle.resetTransactionLaneData());
      dispatch(PropertyHandlers.resetPropertyDocs());
    }
    if (getIndividualTransactionData?.offerId && RTDInitiator) {
      if (reloadSection !== "RTD") {
        dispatch(transactionLaneDataHandle.getTransactionLaneMethod({ offerId: getIndividualTransactionData?.offerId, role: client, unmounted, source, delegateSide }));
      }
      dispatch(
        transactionLaneDataHandle.getPropertyDocs({
          offerId: getIndividualTransactionData?.offerId,
          source,
          unmounted,
          delegateSide,
        })
      );
    } else if (selectedData?.offerId) {
      if (reloadSection !== "RTD") {
        dispatch(transactionLaneDataHandle.getTransactionLaneMethod({ offerId: selectedData?.offerId, role: client, unmounted, source, delegateSide }));
      }
      dispatch(
        transactionLaneDataHandle.getPropertyDocs({
          offerId: selectedData?.offerId,
          source,
          unmounted,
          delegateSide,
        })
      );
    }

    let params = {};
    if (RTDInitiator) {
      params = {
        ...(getIndividualTransactionData?.offerId ? { offerId: getIndividualTransactionData?.offerId } : {}),
        ...((client === "SELLER") && (getIndividualTransactionData?.persistId ? { ertsId: getIndividualTransactionData?.persistId } : {})),
        ...((client === "SELLER") && (getIndividualTransactionData?.thirdpartyId ? { thirdpartyIdDoc: getIndividualTransactionData?.thirdpartyId } : {})),
        openFor: RTDInitiator === "BUYER" ? "BUYER_AGENT" : "SELLER_AGENT",
        personId: (RTDInitiator === "SELLER") ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id,
        ...(delegate ? (RTDInitiator === "SELLER" ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {}),
      };
    } else
      params = {
        ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
        ...((client === "SELLER") && (selectedData?.persistId ? { ertsId: selectedData?.persistId }: selectedData?.propertyId ? { propertyId: selectedData?.propertyId } : {})),
        ...((client === "SELLER") && (selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData?.thirdpartyId } : {})),
        openFor: selectedData?.isErts || selectedData?.isProperty ? "SELLER_AGENT" : "BUYER_AGENT",
        personId: (selectedData?.isErts || selectedData?.isProperty) ? selectedData?.sellerAgentId?._id : selectedData?.buyerAgentId?._id,
        ...(delegate ? (selectedData?.isErts || selectedData?.isProperty ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {}),
      };

      if(screenSource!=="CLIENT_DOC" ) {
    dispatch(PropertyHandlers.getPropertyDocsFun({ params, unmounted, source }));
      }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, []);
  const handleClientDocChange = (documentId, sentBy) => {
    // Create a new array of clientDocumentSelected
    let flag = [...data?.clientDocumentSelected];
    
    // Create an object with documentId
    const newDoc = { documentId,sentBy };
    // Check if the documentId already exists in the array
    const index = flag.findIndex(item => item.documentId === documentId);
    let newSelectedDocs;
    if (index !== -1) {
        // Remove the existing document object from the array
        newSelectedDocs = flag.filter((_, i) => i !== index);
    } else {
        // Add the new document object to the array
        newSelectedDocs = [...flag, newDoc];
    }
    // Update the state with the new array
    setData({
        ...data,
        clientDocumentSelected: newSelectedDocs
    });
};

  const handleCheckboxChange = (documentId) => {
    let flag = [...data?.documentSelected];
    const value = flag?.includes(documentId) ? { documentSelected: flag?.filter((id) => id !== documentId) } : { documentSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };

  const handleSharedDocs = (documentId) => {
    let flag = [...data?.sharedSelected];
    const value = flag?.includes(documentId) ? { sharedSelected: flag?.filter((id) => id !== documentId) } : { sharedSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };

  const handlePropertyDocs = (documentId, type) => {
    let flag = [];

    if (type === "PROPETY_DOC") {
      flag = [...data?.propertyDocsSelected];
    } else if (type === "DOCUMENT_WITHOUT_BUYERS") {
      flag = [...data?.documentWithoutBuyerSelected];
    }

    const value = flag.includes(documentId) ? flag.filter((id) => id !== documentId) : [...flag, documentId];

    setData({
      ...data,
      ...(type === "PROPETY_DOC" ? { propertyDocsSelected: value } : {}),
      ...(type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: value } : {})
    });
  };

  const handleSelectAll = ({ value, element, type }) => {
    if (value) {
      const allDocumentIds = element.map((document) => (type === "DOCUMENT" ? document.documentId : document._id));
     if (type === "CLIENT DOCUMENT") {
        // Map to include both documentId and sentBy for CLIENT DOCUMENT
        const clientDocumentObjects = element.map((document) => ({
          documentId: document._id, // Assuming _id is used as documentId
          sentBy:  crmSellerDocs.includes(document?.contractType)?"SELLER_AGENT":"BUYER_AGENT"
        }));

        setData(prevData => ({
          ...prevData,
          clientDocumentSelected: clientDocumentObjects
        }));
      } else {
        // Handle other types
        const allDocumentIds = element.map((document) => (type === "DOCUMENT" ? document.documentId : document._id));
        setData(prevData => ({
          ...prevData,
          ...(type === "DOCUMENT" ? { documentSelected: allDocumentIds }
            : type === "SHARED" ? { sharedSelected: allDocumentIds }
              : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: allDocumentIds }
                : { propertyDocsSelected: allDocumentIds }),
        }));
      }
    } else {
      // Handle the case where value is false
      setData(prevData => ({
        ...prevData,
        ...(type === "DOCUMENT" ? { documentSelected: [] }
          : type === "SHARED" ? { sharedSelected: [] }
            : type === "CLIENT DOCUMENT" ? { clientDocumentSelected: [] }
              : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: [] }
                : { propertyDocsSelected: [] }),
      }));
    }
  };

const documentsWithCreatedDocuments = personDocs?.filter(doc => doc.createdDocuments.length > 0);
// Transformation logic
const transformedDocuments = [];
documentsWithCreatedDocuments?.forEach(doc => {
  const { createdDocuments, doc_second, ...rest } = doc;
  for (let i = 0; i < createdDocuments.length; i++) {
    const newDocEntry = { ...rest };
    if (i === 0 && doc_second.length > 0) {
      newDocEntry.name += ':';
      doc_second.forEach(docSecond => {
        newDocEntry[`    ${docSecond.name}`] = docSecond; // Indent doc_second entries under the main documen
      });
    }
    transformedDocuments.push(newDocEntry);
  }
});
const isChecked = data?.clientDocumentSelected?.some(doc => doc.documentId === document._id);


  return (
    <div style={{ width: "100%" }}>
      {screenSource === "CLIENT_DOC" && personDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : screenSource === "CLIENT_DOC" && getTransactionLaneError ? (
        ""
      ) : (
        screenSource === "CLIENT_DOC" && clientDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Client Documents:</p>
                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: clientDocs, type: "CLIENT DOCUMENT" })} checked={data?.clientDocumentSelected?.length === clientDocs?.length}>
                    <text style={{ color: "grey" }}>Select All</text>
                  </Checkbox>
                </div>
                {clientDocs?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", flexDirection: "column" }}>
                    {document?.isSecondLevel && (
                      <>
                        <div style={{ marginLeft: "20px" }}>
                          <Checkbox
                            style={{ marginRight: "10px" }}
                            checked={data?.clientDocumentSelected.some(item => item.documentId === document._id)}
                            onChange={() => handleClientDocChange(document._id,crmSellerDocs.includes(document?.contractType)?"SELLER_AGENT":"BUYER_AGENT")}
                          >
                            {document.contractName}
                          </Checkbox>
                        </div>
                      </>
                    )}
                    {!document.isSecondLevel && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          style={{ marginRight: "10px" }}
                          checked={data?.clientDocumentSelected.some(item => item.documentId == document._id)}
                          onChange={() => handleClientDocChange(document._id, crmSellerDocs.includes(document?.contractType)?"SELLER_AGENT":"BUYER_AGENT")}
                        >
                          {document?.contractName}
                        </Checkbox>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

      {/* {getTransactionLaneLoading ? (
        <div>
          <Loading />
        </div>
      ) : getTransactionLaneError ? (
        ""
      ) : (
        getTransactionLaneData?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Transaction Documents:</p>
                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: getTransactionLaneData, type: "DOCUMENT" })} checked={data?.documentSelected?.length === getTransactionLaneData?.length}>
                    <p style={{ color: "grey" }}>Select All</p>
                  </Checkbox>
                </div>
                {getTransactionLaneData?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                    <Checkbox style={{ marginRight: "10px" }} checked={data?.documentSelected?.includes(document.documentId)} onChange={() => handleCheckboxChange(document.documentId)}>
                      {document.contractName} {document?.isPdf && <FilePdfOutlined />}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )} */}

      {/* Property documents list  */}
      {screenSource !== "CLIENT_DOC" && propertDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : screenSource !== "CLIENT_DOC" && propertDocsError ? (
        ""
      ) : (
        screenSource !== "CLIENT_DOC" && propertyDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Transaction & Property Documents:</p>
                  <Checkbox
                    onChange={(e) => handleSelectAll({
                      value: e.target.checked,
                      element: propertyDocs?.filter(
                        ele =>
                          (ele?.contractType === "ERTS" || ele?.contractType === "LCAES") ||
                          ele?.hasOwnProperty('isShared') ||
                          ele?.offerId
                      ),
                      type: "PROPETY_DOC"
                    })}
                    checked={
                      data?.propertyDocsSelected?.length ===
                      propertyDocs?.filter(
                        ele =>
                          (ele?.contractType === "ERTS" || ele?.contractType === "LCAES") ||
                          ele?.hasOwnProperty('isShared') ||
                          ele?.offerId
                      )?.length
                    }
                  >
                    <p style={{ color: "grey" }}>Select All</p>
                  </Checkbox>
                </div>
                {propertyDocs
                  ?.filter(
                    ele =>
                      (ele?.contractType === "ERTS" || ele?.contractType === "LCAES") ||
                      ele?.hasOwnProperty('isShared') ||
                      ele?.offerId
                  )
                  ?.map((document, index) => (
                    <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                      <Checkbox
                        style={{ marginRight: "10px" }}
                        checked={data?.propertyDocsSelected?.includes(document._id)}
                        onChange={() => handlePropertyDocs(document._id, "PROPETY_DOC")}
                      >
                        {document?.contractId ? document?.documentName : document?.fileName}
                      </Checkbox>
                    </div>
                  ))}
              </div>
              {/* Section for Documents without Buyers */}
              {propertyDocs?.filter(
                ele =>
                  !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") &&
                  !ele?.hasOwnProperty('isShared') &&
                  !(ele?.offerId)
              )?.length > 0 && (
                  <div style={{ padding: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                      <p>Documents without Buyers:</p>
                      <Checkbox
                        onChange={(e) => handleSelectAll({
                          value: e.target.checked,
                          element: propertyDocs?.filter(
                            ele =>
                              !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") &&
                              !ele?.hasOwnProperty('isShared') &&
                              !(ele?.offerId)
                          ),
                          type: "DOCUMENT_WITHOUT_BUYERS"
                        })}
                        checked={
                          data?.documentWithoutBuyerSelected?.length ===
                          propertyDocs?.filter(
                            ele =>
                              !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") &&
                              !ele?.hasOwnProperty('isShared') &&
                              !(ele?.offerId)
                          )?.length
                        }
                      >
                        <p style={{ color: "grey" }}>Select All</p>
                      </Checkbox>
                    </div>
                    {propertyDocs
                      ?.filter(
                        ele =>
                          !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") &&
                          !ele?.hasOwnProperty('isShared') &&
                          !(ele?.offerId)
                      )
                      ?.map((document, index) => (
                        <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                          <Checkbox
                            style={{ marginRight: "10px" }}
                            checked={data?.documentWithoutBuyerSelected?.includes(document?._id)}
                            onChange={() => handlePropertyDocs(document._id, "DOCUMENT_WITHOUT_BUYERS")}
                          >
                            {document?.contractId ? document?.documentName : document?.fileName}
                          </Checkbox>
                        </div>
                      ))}
                  </div>
                )}
            </div>
          </div>
        )
      )}

      {/* shared document list  */}
      {getPropertyDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : getPropertyDocsError ? (
        ""
      ) : (
        getPropertyDocsData?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Shared Documents:</p>
                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: getPropertyDocsData, type: "SHARED" })} checked={data?.sharedSelected?.length === getPropertyDocsData?.length}>
                    <p style={{ color: "grey" }}>Select All</p>
                  </Checkbox>
                </div>
                {getPropertyDocsData?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                    <Checkbox style={{ marginRight: "10px" }} checked={data?.sharedSelected?.includes(document._id)} onChange={() => handleSharedDocs(document._id)}>
                      {document.fileName}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DocumentList;
